import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  connect() {
    new TomSelect(this.element, {
      plugins: ['remove_button'],
      onItemAdd: () => {
        this.validate();
      },
      onItemRemove: () => {
        this.validate();
      }
    });
  }

  validate() {
    const form = this.element.closest('form');
    if (form) {
      form.reportValidity();
    }
  }
}