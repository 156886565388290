import { Controller } from "@hotwired/stimulus"
import Trix from "trix"

// Handles @mentions in Trix editor
export default class extends Controller {
  static targets = ["input", "suggestions"]
  static values = {
    suggestionsUrl: String
  }

  connect() {
    this.selectedIndex = -1
    // Remove any existing portals first
    const existingPortal = document.getElementById('mentions-portal')
    if (existingPortal) {
      existingPortal.remove()
    }

    // Wait for Trix to be initialized
    if (this.inputTarget && this.inputTarget.editor) {
      this.setupMentions()
    } else {
      this.element.addEventListener('trix-initialize', () => {
        // Ensure editor is available
        if (this.inputTarget && this.inputTarget.editor) {
          this.setupMentions()
        }
      })
    }

    // Add event listener for mention:select
    this.element.addEventListener('mention:select', (event) => {
      console.log('mention:select event received', event.detail)
      const { name, email } = event.detail
      this.selectUser({ currentTarget: { dataset: { name, email } } })
    })
  }

  setupMentions() {
    // Initialize mention handling
    this.mentionPattern = /(?<![\w@])@(\w*)$/
    this.boundHandleClickOutside = this.handleClickOutside.bind(this)
    this.boundHandleEscape = this.handleEscape.bind(this)

    document.addEventListener("click", this.boundHandleClickOutside)
    document.addEventListener("keydown", this.boundHandleEscape)

    // Create suggestions portal
    this.portalContainer = document.createElement('div')
    this.portalContainer.id = 'mentions-portal'
    this.portalContainer.style.position = 'fixed'
    this.portalContainer.style.zIndex = '9999'
    document.body.appendChild(this.portalContainer)

    // Register mention attachment with Trix
    this.registerMentionAttachment()
  }

  disconnect() {
    document.removeEventListener("click", this.boundHandleClickOutside)
    document.removeEventListener("keydown", this.boundHandleEscape)
    if (this.portalContainer) {
      this.portalContainer.remove()
    }
  }

  handleEscape(event) {
    if (event.key === "Escape") {
      this.hideSuggestions()
    }
  }

  onKeydown(event) {
    if (!this.portalContainer?.querySelector('.dropdown-content')) return

    switch (event.key) {
      case "ArrowDown":
        event.preventDefault()
        this.navigateSuggestions(1)
        break
      case "ArrowUp":
        event.preventDefault()
        this.navigateSuggestions(-1)
        break
      case "Enter":
      case "Tab":
        if (this.selectedIndex >= 0) {
          event.preventDefault()
          const selectedItem = this.portalContainer.querySelectorAll('.mention-suggestion')[this.selectedIndex]
          if (selectedItem) this.selectUser({ currentTarget: selectedItem })
        }
        break
      case "Escape":
        this.handleEscape(event)
        break
    }
  }

  navigateSuggestions(direction) {
    const suggestions = this.portalContainer.querySelectorAll('.mention-suggestion')
    if (!suggestions.length) return

    // Remove highlight from current selection
    suggestions[this.selectedIndex]?.classList.remove('bg-gray-100')

    // Update selected index
    this.selectedIndex += direction
    if (this.selectedIndex < 0) this.selectedIndex = suggestions.length - 1
    if (this.selectedIndex >= suggestions.length) this.selectedIndex = 0

    // Add highlight to new selection
    const selectedItem = suggestions[this.selectedIndex]
    selectedItem.classList.add('bg-gray-100')
    selectedItem.scrollIntoView({ block: 'nearest' })
  }

  registerMentionAttachment() {
    // Configure Trix to handle mentions
    if (!Trix.config.textAttributes.mention) {
      Trix.config.textAttributes.mention = {
        tagName: "span",
        className: "mention inline-flex items-center px-2 py-0.5 rounded-full text-sm font-medium bg-emerald-100 text-emerald-800",
        parser: (element) => {
          return {
            userId: element.dataset.user,
            name: element.dataset.name
          }
        }
      }
    }

    // Register mention attachment type with inline presentation
    if (!Trix.config.attachments.mention) {
      Trix.config.attachments.mention = {
        preview: false,
        presentation: "inline",
        render(attachment) {
          const wrapper = document.createElement("span")
          wrapper.className = "mention inline-flex items-center px-2 py-0.5 rounded-full text-sm font-medium bg-emerald-100 text-emerald-800"
          wrapper.dataset.user = attachment.getAttributes().userId
          wrapper.dataset.name = attachment.getAttributes().name
          wrapper.dataset.userId = attachment.getAttributes().userId.split('@')[0] // Use email prefix as userId if not available

          // Create initials container
          const initialsSpan = document.createElement("span")
          initialsSpan.className = "w-4 h-4 rounded-full bg-emerald-200 flex items-center justify-center text-xs font-bold mr-1"
          const initials = attachment.getAttributes().name
            .split('@')[0] // Get the part before the @ in email
            .split(' ')
            .map(part => part[0])
            .join('')
            .toUpperCase()
            .slice(0, 2)
          initialsSpan.textContent = initials

          // Create name container
          const nameSpan = document.createElement("span")
          nameSpan.textContent = `@${attachment.getAttributes().name.split('@')[0]}` // Only show name part

          wrapper.appendChild(initialsSpan)
          wrapper.appendChild(nameSpan)
          return wrapper
        }
      }
    }
  }

  onInput(event) {
    if (!this.inputTarget?.editor) return

    const editor = this.inputTarget.editor
    const position = editor.getSelectedRange()[0]
    const text = editor.getDocument().toString()
    const textBeforeCursor = text.substring(0, position)
    const match = textBeforeCursor.match(/@[^@\s]*$/)

    if (match) {
      const query = match[0].slice(1) // Remove @ symbol
      this.searchUsers(query)
    } else {
      this.hideSuggestions()
    }
  }

  async searchUsers(query = "") {
    try {
      const response = await fetch(`${this.suggestionsUrlValue}?q=${encodeURIComponent(query)}`)
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)

      const users = await response.json()
      this.showSuggestions(users)
    } catch (error) {
      console.error("Error fetching user suggestions:", error)
      this.hideSuggestions()
    }
  }

  showSuggestions(users) {
    if (!users.length) {
      this.hideSuggestions()
      return
    }

    this.selectedIndex = -1
    const suggestionsEl = document.createElement('div')
    suggestionsEl.className = 'dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-64 max-h-96 overflow-y-auto'

    users.forEach(user => {
      const item = document.createElement('div')
      item.className = 'mention-suggestion p-2 hover:bg-gray-100 cursor-pointer rounded flex items-center gap-2'
      item.dataset.name = user.name
      item.dataset.email = user.email

      const initials = user.name
        .split(' ')
        .map(part => part[0])
        .join('')
        .toUpperCase()
        .slice(0, 2)

      item.innerHTML = `
        <div class="w-8 h-8 rounded-full bg-emerald-100 flex items-center justify-center text-sm font-medium text-emerald-800">
          ${initials}
        </div>
        <div>
          <div class="font-medium">${user.name}</div>
          <div class="text-xs text-gray-500">${user.email}</div>
        </div>
      `

      item.addEventListener('click', (e) => this.selectUser(e))
      suggestionsEl.appendChild(item)
    })

    // Position near cursor
    const editorRect = this.inputTarget.getBoundingClientRect()
    const caretRange = this.inputTarget.editor.getClientRectAtPosition(
      this.inputTarget.editor.getSelectedRange()[0]
    )

    this.portalContainer.innerHTML = ''
    this.portalContainer.style.display = 'block'
    this.portalContainer.appendChild(suggestionsEl)

    if (caretRange) {
      const spaceBelow = window.innerHeight - caretRange.bottom
      const spaceAbove = caretRange.top

      suggestionsEl.style.position = 'fixed'
      if (spaceBelow < 200 && spaceAbove > spaceBelow) {
        suggestionsEl.style.bottom = `${window.innerHeight - caretRange.top}px`
      } else {
        suggestionsEl.style.top = `${caretRange.bottom + window.scrollY}px`
      }
      suggestionsEl.style.left = `${caretRange.left + window.scrollX}px`
    }
  }

  selectUser(event) {
    const { name, email } = event.currentTarget?.dataset || event.detail || {}
    if (!name || !email) {
      console.error('Missing user data for mention')
      return
    }

    const editor = this.inputTarget.editor
    const position = editor.getSelectedRange()[0]
    const text = editor.getDocument().toString()
    const textBeforeCursor = text.substring(0, position)
    const lastAtSymbol = textBeforeCursor.lastIndexOf("@")

    // Delete the @ symbol and any search text
    editor.setSelectedRange([lastAtSymbol, position])
    editor.deleteInDirection("backward")

    // Create the mention element directly
    const wrapper = document.createElement("span")
    wrapper.className = "mention inline-flex items-center px-2 py-0.5 rounded-full text-sm font-medium bg-emerald-100 text-emerald-800"
    wrapper.dataset.user = email
    wrapper.dataset.name = name

    // Try to extract a numeric user ID from the email, or default to 1
    const emailPrefix = email.split('@')[0]
    const userId = /^\d+$/.test(emailPrefix) ? emailPrefix : '1'
    wrapper.dataset.userId = userId

    wrapper.contentEditable = false

    // Create initials container
    const initialsSpan = document.createElement("span")
    initialsSpan.className = "w-4 h-4 rounded-full bg-emerald-200 flex items-center justify-center text-xs font-bold mr-1"
    const initials = name
      .split('@')[0]
      .split(' ')
      .map(part => part[0])
      .join('')
      .toUpperCase()
      .slice(0, 2)
    initialsSpan.textContent = initials

    // Create name container
    const nameSpan = document.createElement("span")
    nameSpan.textContent = `@${name.split('@')[0]}`

    wrapper.appendChild(initialsSpan)
    wrapper.appendChild(nameSpan)

    // Create a custom attachment that will preserve our HTML structure
    const attachment = new Trix.Attachment({
      content: wrapper.outerHTML,
      contentType: "mention",
      name: name,
      userId: userId,
      presentation: "inline",
      attributes: {
        'data-user': email,
        'data-name': name,
        'data-user-id': userId
      }
    })

    editor.insertAttachment(attachment)

    // Only add a space if we're not at the end of a line
    const nextChar = text.charAt(position)
    if (nextChar !== "\n" && nextChar !== "") {
      editor.insertString(" ")
    }

    this.hideSuggestions()
  }

  hideSuggestions() {
    this.selectedIndex = -1
    if (this.portalContainer) {
      this.portalContainer.innerHTML = ''
      this.portalContainer.style.display = 'none'
    }
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target) && !this.portalContainer?.contains(event.target)) {
      this.hideSuggestions()
    }
  }
}
