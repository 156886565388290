import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["search", "teamList"]

  connect() {
    // Initialize any necessary state
  }

  filterTeams(event) {
    const searchTerm = event.target.value.toLowerCase()
    const teams = this.teamListTarget.querySelectorAll('.menu-item')

    // First, hide all teams
    teams.forEach(team => {
      team.style.display = 'none'
    })

    // Then, show teams that match the search term and their ancestors
    teams.forEach(team => {
      const teamName = team.dataset.teamName
      if (teamName && teamName.includes(searchTerm)) {
        // Show this team
        team.style.display = ''

        // Show all parent teams
        this.showParentTeams(team)

        // If this is a parent team, show all child teams that match
        this.showMatchingChildTeams(team, searchTerm)
      }
    })

    // If no search term, show all teams
    if (!searchTerm) {
      teams.forEach(team => {
        team.style.display = ''
      })
    }
  }

  showParentTeams(team) {
    let parent = team.parentElement
    while (parent) {
      if (parent.classList.contains('menu-item')) {
        parent.style.display = ''
      }
      parent = parent.parentElement
    }
  }

  showMatchingChildTeams(team, searchTerm) {
    // Find all child teams
    const childItems = team.querySelectorAll('.menu-item')
    childItems.forEach(child => {
      const childName = child.dataset.teamName
      if (childName && childName.includes(searchTerm)) {
        child.style.display = ''
        this.showParentTeams(child)
      }
    })
  }
} 