import { Controller } from "@hotwired/stimulus"

// Handles flash message dismissal
export default class extends Controller {
  static targets = ["message"]

  connect() {
    if (process.env.NODE_ENV !== 'test') {
      setTimeout(() => {
        this.dismiss()
      }, 5000)
    }
  }

  close(event) {
    event.preventDefault()
    this.dismiss()
  }

  dismiss() {
    this.messageTargets.forEach(target => {
      target.style.opacity = '0'
      target.addEventListener('transitionend', () => {
        target.remove()
      })
    })
  }
} 