import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dialog"]

  connect() {
    this.modal = this.dialogTarget
  }

  open() {
    this.modal.showModal()
  }

  close() {
    this.modal.close()
  }

  handleResponse(event) {
    this.close()
  }
} 