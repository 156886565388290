import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["drawer"]

  connect() {
    const drawerVisible = localStorage.getItem('drawerVisible') === 'true'
    if (!drawerVisible) {
      this.drawerTarget.classList.add('hidden')
      document.querySelector('[data-drawer-target="chevron-left"]').classList.add('hidden')
      document.querySelector('[data-drawer-target="chevron-right"]').classList.remove('hidden')
    }

    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') this.close()
    })
  }

  toggle(event) {
    event.preventDefault()
    const chevronLeft = document.querySelector('[data-drawer-target="chevron-left"]')
    const chevronRight = document.querySelector('[data-drawer-target="chevron-right"]')

    if (this.isOpen()) {
      this.close()
      chevronLeft.classList.add('hidden')
      chevronRight.classList.remove('hidden')
      localStorage.setItem('drawerVisible', 'false')
    } else {
      this.open()
      chevronLeft.classList.remove('hidden')
      chevronRight.classList.add('hidden')
      localStorage.setItem('drawerVisible', 'true')
    }
  }

  open() {
    this.drawerTarget.classList.remove('hidden')
    document.body.classList.add('overflow-hidden')
  }

  close() {
    this.drawerTarget.classList.add('hidden')
    document.body.classList.remove('overflow-hidden')
  }

  isOpen() {
    return !this.drawerTarget.classList.contains('hidden')
  }

  backdropClick(event) {
    if (event.target === this.backdropTarget) {
      this.close()
    }
  }
}
