import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

// Connects to data-controller="notes-navigation"
export default class extends Controller {
  static targets = ["previousButton", "nextButton", "counter"]
  static values = {
    current: Number,
    total: Number
  }

  connect() {
    console.log("NotesNavigation controller connected:", {
      current: this.currentValue,
      total: this.totalValue,
      element: this.element
    })
    this.updateNavigationState()
  }

  // Left chevron - show older notes
  previous(event) {
    event.preventDefault()
    // Move to older notes (higher position number)
    if (this.currentValue < this.totalValue) {
      this.currentValue++
      this.fetchNote()
    }
  }

  // Right chevron - show newer notes
  next(event) {
    event.preventDefault()
    // Move to newer notes (lower position number)
    if (this.currentValue > 1) {
      this.currentValue--
      this.fetchNote()
    }
  }

  updateNavigationState() {
    // Display position should be the reverse of the internal position
    const displayPosition = this.totalValue - this.currentValue + 1
    this.counterTarget.textContent = `${displayPosition}/${this.totalValue}`

    // Update button states
    // Left chevron (previous) disabled when we're at the oldest note (highest position)
    this.previousButtonTarget.disabled = this.currentValue >= this.totalValue
    // Right chevron (next) disabled when we're at the newest note (position 1)
    this.nextButtonTarget.disabled = this.currentValue <= 1

    // Apply visual feedback
    this.element.querySelectorAll('button').forEach(button => {
      if (button.disabled) {
        button.classList.add('opacity-30')
        button.classList.remove('hover:text-gray-900')
      } else {
        button.classList.remove('opacity-30')
        button.classList.add('hover:text-gray-900')
      }
    })
  }

  fetchNote() {
    const frame = document.querySelector(`#${this.element.closest('[id]').id}`)
    const url = new URL(window.location.href)

    // Update URL with new position
    url.searchParams.set('note_position', this.currentValue)

    // Add loading state
    frame.classList.add('opacity-50')

    // Use Turbo.visit instead of visit
    Turbo.visit(url.toString(), {
      frame: frame.id,
      action: "replace"
    })

    // Handle loading state separately since Turbo.visit doesn't return a Promise
    frame.addEventListener('turbo:before-frame-render', () => {
      frame.classList.remove('opacity-50')
      this.updateNavigationState()
    }, { once: true })

    // Add error handling
    frame.addEventListener('turbo:frame-load-error', () => {
      frame.classList.remove('opacity-50')
      console.error('Failed to load note')
    }, { once: true })
  }
} 