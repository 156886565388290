import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["popover", "progress", "status", "comment", "submit"]

  connect() {
    // Close popover when clicking outside
    document.addEventListener("click", this.handleClickOutside.bind(this))

    // Initialize form validation
    this.validateProgress()
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside.bind(this))
  }

  toggle(event) {
    event.preventDefault()
    event.stopPropagation()

    const isHidden = this.popoverTarget.classList.contains("hidden")

    // First hide the popover
    this.popoverTarget.classList.add("hidden")

    // Then show it after a tick to trigger animation
    if (isHidden) {
      requestAnimationFrame(() => {
        this.popoverTarget.classList.remove("hidden")
        // Focus the input
        this.progressTarget.focus()
        this.progressTarget.select()

        // Initialize Trix editor if needed
        const trixEditor = this.commentTarget.querySelector("trix-editor")
        if (trixEditor && !trixEditor.hasAttribute("data-trix-initialized")) {
          trixEditor.setAttribute("data-trix-initialized", "true")
          trixEditor.addEventListener("trix-initialize", () => {
            // Focus the editor after initialization
            trixEditor.focus()
          })
        }
      })
    }
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.hidePopover()
    }
  }

  hidePopover() {
    this.popoverTarget.classList.add("hidden")
  }

  quickSet(event) {
    event.preventDefault()
    const value = event.currentTarget.dataset.value
    this.progressTarget.value = value
    this.validateProgress()
    this.progressTarget.focus()
  }

  validateProgress() {
    const progress = parseInt(this.progressTarget.value)
    if (isNaN(progress) || progress < 0 || progress > 100) {
      this.progressTarget.classList.add("input-error")
      this.submitTarget.disabled = true
    } else {
      this.progressTarget.classList.remove("input-error")
      this.validateForm()
    }
  }

  validateForm() {
    const progress = parseInt(this.progressTarget.value)
    const statusOverride = this.statusTarget.value
    const commentContent = this.commentTarget.value
    const progressChanged = progress !== parseInt(this.progressTarget.defaultValue)
    const statusChanged = statusOverride !== this.statusTarget.defaultValue

    // Check if the comment is empty or just contains HTML formatting
    const isCommentEmpty = !commentContent ||
      commentContent.trim() === "" ||
      commentContent === "<div></div>" ||
      commentContent === "<div><br></div>"

    // Only require comment if progress or status has changed
    if ((progressChanged || statusChanged) && isCommentEmpty) {
      this.submitTarget.disabled = true
      this.commentTarget.classList.add("textarea-error")
    } else {
      this.submitTarget.disabled = false
      this.commentTarget.classList.remove("textarea-error")
    }
  }

  updateProgress(event) {
    const progress = parseInt(this.progressTarget.value)
    const statusOverride = this.statusTarget.value
    const commentContent = this.commentTarget.value
    const progressChanged = progress !== parseInt(this.progressTarget.defaultValue)
    const statusChanged = statusOverride !== this.statusTarget.defaultValue

    // Check if the comment is empty or just contains HTML formatting
    const isCommentEmpty = !commentContent ||
      commentContent.trim() === "" ||
      commentContent === "<div></div>" ||
      commentContent === "<div><br></div>"

    if ((progressChanged || statusChanged) && isCommentEmpty) {
      event.preventDefault()
      this.commentTarget.classList.add("textarea-error")
    } else {
      // Show loading state
      this.submitTarget.classList.add("loading")

      // Add Turbo Stream success handler
      const form = event.target.closest("form")
      form.addEventListener("turbo:submit-end", (e) => {
        if (e.detail.success) {
          this.hidePopover()
        }
        this.submitTarget.classList.remove("loading")
      }, { once: true })
    }
  }
} 