import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["preview", "dropdown", "colorName"]
  
  connect() {
    // Initialize if needed
  }

  updatePreview(event) {
    const selectedColor = event.target.value
    const selectedName = event.target.dataset.colorName
    
    // Update the color preview
    this.previewTarget.style.backgroundColor = selectedColor
    
    // Update the displayed color name
    this.colorNameTarget.textContent = selectedName
    
    // Find and focus the save button by searching up through parents
    const form = this.element.closest('form')
    if (form) {
      const saveButton = form.querySelector('input[type="submit"]')
      if (saveButton) {
        saveButton.focus()
      }
    }
  }
} 