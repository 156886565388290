import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sidebar"]
  openSidebarBound = null

  connect() {
    this.openSidebarBound = this.openSidebar.bind(this)
    document.addEventListener("turbo:frame-load", this.openSidebarBound)

    // Check URL on initial load
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('selected')) {
      this.open()
    }
  }

  disconnect() {
    document.removeEventListener("turbo:frame-load", this.openSidebarBound)
  }

  open(event) {
    if (this.hasSidebarTarget) {
      this.sidebarTarget.classList.remove("hidden")

      // Update URL if this was triggered by a click
      if (event?.type === "click") {
        const link = event.currentTarget
        const url = new URL(link.href)
        const selected = url.searchParams.get('selected')
        if (selected) {
          const currentUrl = new URL(window.location)
          currentUrl.searchParams.set('selected', selected)
          history.pushState({}, '', currentUrl)
        }
      }
    }
  }

  close() {
    if (this.hasSidebarTarget) {
      this.sidebarTarget.classList.add("hidden")
      const url = new URL(window.location)
      url.searchParams.delete('selected')
      history.pushState({}, '', url)
    }
  }

  openSidebar(event) {
    if (event.target.id === "sidebar_content") {
      this.open()
    }
  }

  toggle() {
    if (this.hasSidebarTarget) {
      if (this.sidebarTarget.classList.contains("hidden")) {
        this.open()
      } else {
        this.close()
      }
    }
  }
}
