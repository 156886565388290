import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content"]

  connect() {
    console.log("Popover controller connected")
    this.boundShowHandler = this.show.bind(this)
    this.boundHideHandler = this.hide.bind(this)

    this.element.addEventListener("mouseenter", this.boundShowHandler)
    this.element.addEventListener("mouseleave", this.boundHideHandler)
  }

  disconnect() {
    console.log("Popover controller disconnected")
    this.element.removeEventListener("mouseenter", this.boundShowHandler)
    this.element.removeEventListener("mouseleave", this.boundHideHandler)
  }

  show() {
    this.contentTarget.classList.remove('hidden')
    this.contentTarget.classList.add('transition-opacity', 'duration-150', 'ease-in-out')
  }

  hide() {
    this.contentTarget.classList.add('hidden')
  }
}