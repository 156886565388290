import { Controller } from "@hotwired/stimulus"

// Handles responsive form behavior for both desktop and mobile
export default class extends Controller {
  connect() {
    // Add mobile class if needed
    if (window.innerWidth < 768) {
      this.element.classList.add('is-mobile')
    }

    // Handle escape key
    document.addEventListener('keydown', this.handleKeydown.bind(this))
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeydown.bind(this))
  }

  close() {
    const frame = this.element.closest('turbo-frame')
    if (frame) {
      // Create an empty div with the same ID to replace the form
      const emptyContent = document.createElement('div')
      emptyContent.id = frame.id

      // Replace the frame's content with the empty div
      frame.innerHTML = ''
      frame.appendChild(emptyContent)
    }
  }

  handleKeydown(event) {
    if (event.key === 'Escape') {
      this.close()
    }
  }
} 