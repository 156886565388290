import { Controller } from "@hotwired/stimulus"

// Handles notification interactions
export default class extends Controller {
  static targets = [
    "dropdown",
    "list",
    "badge",
    "markAllButton",
    "markAllText",
    "markAllSpinner",
    "toggleButton"
  ]

  connect() {
    console.debug("[Notifications] Controller connected")
    // Close dropdown when clicking outside
    document.addEventListener("click", this.handleOutsideClick)
  }

  disconnect() {
    document.removeEventListener("click", this.handleOutsideClick)
  }

  toggle(event) {
    event.stopPropagation()
    console.debug("[Notifications] Toggle dropdown")

    const isVisible = !this.dropdownTarget.classList.contains("hidden")
    if (isVisible) {
      this.hideDropdown()
    } else {
      this.showDropdown()
    }
  }

  showDropdown() {
    console.debug("[Notifications] Show dropdown")
    this.dropdownTarget.classList.remove("hidden")
    this.toggleButtonTarget.setAttribute("aria-expanded", "true")
  }

  hideDropdown() {
    console.debug("[Notifications] Hide dropdown")
    this.dropdownTarget.classList.add("hidden")
    this.toggleButtonTarget.setAttribute("aria-expanded", "false")
  }

  handleOutsideClick = (event) => {
    if (!this.element.contains(event.target)) {
      this.hideDropdown()
    }
  }

  // Handle notification click
  async handleClick(event) {
    const link = event.target.closest("a[data-mark-read-url]")
    if (!link) return

    event.preventDefault()
    console.debug("[Notifications] Clicked notification", link.dataset)

    // Optimistically update UI
    const notification = link.closest("[data-unread]")
    if (notification && notification.dataset.unread === "true") {
      notification.querySelector(".bg-base-200")?.classList.remove("bg-base-200")
      notification.querySelector(".bg-primary")?.remove()
      notification.dataset.unread = "false"
    }

    // Mark as read in background
    try {
      const response = await fetch(link.dataset.markReadUrl, {
        method: "POST",
        headers: {
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
          "Accept": "text/vnd.turbo-stream.html"
        }
      })

      if (!response.ok) throw new Error("Failed to mark as read")

      // Update badge count if needed
      if (this.hasBadgeTarget && notification.dataset.unread === "true") {
        this.updateUnreadCount(-1)
      }

      // Navigate to target URL
      window.location = link.href
    } catch (error) {
      console.error("[Notifications] Failed to mark as read:", error)
      // Revert optimistic update
      if (notification) {
        notification.querySelector(".rounded-lg").classList.add("bg-base-200")
        notification.dataset.unread = "true"
      }
    }
  }

  // Handle mark all as read
  async markAllAsRead(event) {
    event.preventDefault()
    console.debug("[Notifications] Mark all as read clicked")

    const button = this.markAllButtonTarget
    const form = button.closest('form')

    console.debug("[Notifications] Form element:", form)
    console.debug("[Notifications] Form action:", form?.action)
    console.debug("[Notifications] Button debug URL:", button.dataset.debugUrl)
    console.debug("[Notifications] Current location:", window.location.href)

    // Get the action URL, falling back to the debug URL if form action is missing
    const actionUrl = form?.action || button.dataset.debugUrl
    if (!actionUrl) {
      console.error("[Notifications] No valid action URL found")
      this.dispatch("error", { detail: "Configuration error" })
      return
    }

    console.debug("[Notifications] Using action URL:", actionUrl)

    // Show loading state
    this.markAllTextTarget.textContent = "Marking as read..."
    this.markAllSpinnerTarget.classList.remove("hidden")
    this.markAllButtonTarget.disabled = true

    try {
      const response = await fetch(actionUrl, {
        method: "POST",
        headers: {
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
          "Accept": "text/vnd.turbo-stream.html"
        }
      })

      console.debug("[Notifications] Response status:", response.status)
      console.debug("[Notifications] Response headers:", Object.fromEntries(response.headers))

      if (!response.ok) {
        const responseText = await response.text()
        console.error("[Notifications] Server error response:", responseText)
        throw new Error("Failed to mark all as read")
      }

      // Update UI
      this.listTarget.querySelectorAll("[data-unread]").forEach(notification => {
        notification.classList.remove("bg-base-200")
        notification.querySelector(".bg-primary")?.remove()
      })

      // Reset badge count
      if (this.hasBadgeTarget) {
        this.badgeTarget.remove()
      }

      // Show success message
      this.dispatch("success", { detail: "All notifications marked as read" })
    } catch (error) {
      console.error("[Notifications] Error details:", error)
      this.dispatch("error", { detail: "Failed to mark all as read" })
    } finally {
      // Reset button state
      this.markAllTextTarget.textContent = "Mark all as read"
      this.markAllSpinnerTarget.classList.add("hidden")
      this.markAllButtonTarget.disabled = false
    }
  }

  updateUnreadCount(change) {
    if (!this.hasBadgeTarget) return

    const countElement = this.badgeTarget.querySelector("[data-count]")
    if (!countElement) return

    const currentCount = parseInt(countElement.textContent)
    const newCount = Math.max(0, currentCount + change)

    console.debug("[Notifications] Update count:", { currentCount, newCount, change })

    if (newCount === 0) {
      this.badgeTarget.remove()
    } else {
      countElement.textContent = newCount
    }
  }
} 