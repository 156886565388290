import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.boundShow = this.show.bind(this)
    this.boundHide = this.hide.bind(this)

    // Find the trigger element that references this tooltip
    this.trigger = document.querySelector(`[data-tooltip-target="${this.element.id}"]`)
    if (this.trigger) {
      this.trigger.addEventListener('mouseenter', this.boundShow)
      this.trigger.addEventListener('mouseleave', this.boundHide)
    }
  }

  disconnect() {
    if (this.trigger) {
      this.trigger.removeEventListener('mouseenter', this.boundShow)
      this.trigger.removeEventListener('mouseleave', this.boundHide)
    }
  }

  show() {
    // Position the tooltip
    const triggerRect = this.trigger.getBoundingClientRect()
    this.element.style.top = `${triggerRect.bottom + window.scrollY + 5}px`
    this.element.style.left = `${triggerRect.left + window.scrollX}px`

    // Show with a fade
    this.element.classList.remove('hidden')
    this.element.classList.add('opacity-0')
    requestAnimationFrame(() => {
      this.element.classList.add('transition-opacity', 'duration-150')
      this.element.classList.remove('opacity-0')
    })
  }

  hide() {
    this.element.classList.add('opacity-0')
    setTimeout(() => {
      this.element.classList.add('hidden')
      this.element.classList.remove('transition-opacity', 'duration-150')
    }, 150)
  }
} 