import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    // On initial page load, sync localStorage with the user's saved theme
    const userTheme = document.documentElement.dataset.theme
    if (userTheme) {
      localStorage.setItem('theme', userTheme)
    }

    // If there's a theme in localStorage (from another tab/window), use it
    const storedTheme = localStorage.getItem('theme')
    if (storedTheme) {
      document.documentElement.setAttribute('data-theme', storedTheme)
    }

    // Listen for theme changes from other tabs/windows
    window.addEventListener('storage', this.handleStorageChange.bind(this))
  }

  disconnect() {
    window.removeEventListener('storage', this.handleStorageChange.bind(this))
  }

  handleStorageChange(event) {
    if (event.key === 'theme' && event.newValue) {
      document.documentElement.setAttribute('data-theme', event.newValue)
    }
  }
}
