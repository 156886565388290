import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "select",
    "search",
    "validation",
    "preview",
    "submit"
  ]

  connect() {
    // Store complete structure including optgroups
    this.originalStructure = Array.from(this.selectTarget.children).map(child =>
      child.cloneNode(true)
    );
    this.currentTeamId = this.selectTarget.dataset.teamId;
    this.currentTeamName = this.selectTarget.dataset.teamName;
    this.currentPeriodId = this.selectTarget.dataset.periodId;
    this.parentTeamId = this.selectTarget.dataset.parentTeamId;
  }

  // Filter handlers
  filterMyTeam() {
    this.filterOptionsByTeamId(this.currentTeamId);
    this.setActiveFilterButton('my-team');
  }

  filterParentTeam() {
    this.filterOptionsByTeamId(this.parentTeamId);
    this.setActiveFilterButton('parent-team');
  }

  filterOrganization() {
    this.filterOptionsByTeamId(this.parentTeamId);
    this.setActiveFilterButton('organization');
  }

  resetFilter() {
    this.resetOptions();
    this.setActiveFilterButton(null);
    this.updateValidation();
  }

  // Search handler
  search() {
    const query = this.searchTarget.value.toLowerCase()

    // Reset options first
    this.resetOptions()

    if (!query) return

    // Filter options based on search
    Array.from(this.selectTarget.options).forEach(option => {
      if (!option.text.toLowerCase().includes(query)) {
        option.remove()
      }
    })

    this.updateValidation()
  }

  // Preview handler
  preview() {
    const selectedOption = this.selectTarget.selectedOptions[0]
    if (!selectedOption) return

    const data = selectedOption.dataset
    if (!data) return

    // Show validation preview
    const validations = this.validateAlignment(data)
    this.showValidationPreview(validations)

    // Always load preview, but show validation state
    this.loadPreview()
  }

  // Validation
  validateSubmit(event) {
    const selectedOption = this.selectTarget.selectedOptions[0]
    if (!selectedOption) {
      event.preventDefault()
      this.showValidationPreview({
        valid: false,
        errors: ["Please select a target to align with"]
      })
      return
    }

    const validations = this.validateAlignment(selectedOption.dataset)
    if (!validations.valid) {
      event.preventDefault()
      this.showValidationPreview(validations)
      return
    }

    // Add info about successful validation
    validations.info = ["Ready to create alignment"]
    this.showValidationPreview(validations)
  }

  validateAlignment(data) {
    const validations = {
      valid: true,
      errors: [],
      warnings: [],
      info: []
    }

    // Check period match
    if (data.periodId !== this.currentPeriodId) {
      validations.valid = false
      validations.errors.push("Must align within the same period")
    }

    // Check team hierarchy
    if (data.teamId === this.currentTeamId) {
      validations.valid = false
      validations.errors.push("Cannot align to the same team")
    }

    // Add warnings for unusual but allowed cases
    if (!this.isValidTeamHierarchy(data.teamId)) {
      validations.warnings.push("Unusual team hierarchy alignment")
    }

    // Add info for valid cases
    if (validations.valid) {
      validations.info.push(`Will create alignment with ${data.team}`)
    }

    return validations
  }

  // Private methods
  filterOptionsByTeamId(teamId) {
    this.resetOptions();

    // Handle optgroups
    Array.from(this.selectTarget.children).forEach(child => {
      if (child.tagName === 'OPTGROUP') {
        // Keep group, filter its options
        Array.from(child.children).forEach(option => {
          if (option.dataset.teamId !== teamId) {
            option.remove();
          }
        });

        // Remove empty groups
        if (!child.children.length) {
          child.remove();
        }
      } else if (child.tagName === 'OPTION' && child.value) {
        // Handle ungrouped options (excluding prompt)
        if (child.dataset.teamId !== teamId) {
          child.remove();
        }
      }
    });

    this.updateValidation();
  }

  resetOptions() {
    this.selectTarget.innerHTML = "";
    this.originalStructure.forEach(child => {
      this.selectTarget.appendChild(child.cloneNode(true));
    });
  }

  updateValidation() {
    const count = this.selectTarget.options.length - 1 // Subtract prompt
    this.validationTarget.innerHTML = count === 0
      ? '<div class="text-sm text-warning">No matching targets found</div>'
      : `<div class="text-sm text-base-content/70">${count} targets available</div>`
  }

  showValidationPreview(validations) {
    let html = '<div class="space-y-2">'

    // Errors
    if (validations.errors?.length) {
      html += '<div class="text-error">'
      validations.errors.forEach(error => {
        html += `<div class="flex items-center gap-2">
          <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" />
          </svg>
          ${error}
        </div>`
      })
      html += '</div>'
    }

    // Warnings
    if (validations.warnings?.length) {
      html += '<div class="text-warning">'
      validations.warnings.forEach(warning => {
        html += `<div class="flex items-center gap-2">
          <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" />
          </svg>
          ${warning}
        </div>`
      })
      html += '</div>'
    }

    // Info
    if (validations.info?.length) {
      html += '<div class="text-info">'
      validations.info.forEach(info => {
        html += `<div class="flex items-center gap-2">
          <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" />
          </svg>
          ${info}
        </div>`
      })
      html += '</div>'
    }

    html += '</div>'
    this.validationTarget.innerHTML = html
  }

  loadPreview() {
    const selectedId = this.selectTarget.value
    if (!selectedId) return

    const direction = this.selectTarget.dataset.direction
    const previewUrl = this.selectTarget.dataset.previewUrl

    this.previewTarget.classList.remove('hidden')

    // Load preview via Turbo
    const url = `${previewUrl}?target_id=${selectedId}&direction=${direction}`
    Turbo.visit(url, { frame: "alignment_preview" })
  }

  isValidTeamHierarchy(teamId) {
    // Simple check - could be enhanced based on actual hierarchy rules
    return teamId === "organization" ||
      teamId === this.parentTeamId ||
      teamId === this.currentTeamId
  }

  setActiveFilterButton(type) {
    const buttons = this.element.querySelectorAll('.btn');
    buttons.forEach(btn => btn.classList.remove('btn-active'));

    if (type) {
      const selector = `[data-action="click->alignment-form#filter${type.charAt(0).toUpperCase() + type.slice(1)}"]`;
      this.element.querySelector(selector)?.classList.add('btn-active');
    }
  }
} 