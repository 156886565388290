import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  connect() {
    this.element.addEventListener("turbo:submit-end", this.handleSubmission.bind(this))
    this.element.addEventListener("submit", this.validateBeforeSubmit.bind(this))

    // Add Trix change listener
    const trixEditor = this.element.querySelector("trix-editor")
    if (trixEditor) {
      trixEditor.addEventListener("trix-change", this.handleTrixChange.bind(this))
      // Remove required attribute to prevent Safari issues
      trixEditor.removeAttribute("required")
    }

    // Remove required from hidden input as well
    const hiddenInput = this.element.querySelector('input[type="hidden"][name*="[content]"]')
    if (hiddenInput) {
      hiddenInput.removeAttribute("required")
    }

    this.validateForm()
  }

  disconnect() {
    this.element.removeEventListener("turbo:submit-end", this.handleSubmission.bind(this))
    this.element.removeEventListener("submit", this.validateBeforeSubmit.bind(this))

    const trixEditor = this.element.querySelector("trix-editor")
    if (trixEditor) {
      trixEditor.removeEventListener("trix-change", this.handleTrixChange.bind(this))
    }
  }

  handleTrixChange(event) {
    const trixEditor = event.target
    const content = trixEditor.editor.getDocument().toString().trim()
    const hiddenInput = this.element.querySelector('input[type="hidden"][name*="[content]"]')

    // Update hidden input with current content
    if (hiddenInput) {
      hiddenInput.value = trixEditor.value
    }

    // Remove visual error state if content is valid
    if (content !== "") {
      trixEditor.setAttribute("aria-invalid", "false")
      this.clearError()
    }
  }

  validateForm() {
    const form = this.element
    const progress = parseInt(form.querySelector('input[name*="[progress]"]').value)
    const statusOverride = form.querySelector('select[name*="[status_override]"]').value
    const commentContent = form.querySelector('trix-editor').value

    // Check if progress is valid
    if (isNaN(progress) || progress < 0 || progress > 100) {
      form.querySelector('input[name*="[progress]"]').classList.add("input-error")
      form.querySelector('input[type="submit"]').disabled = true
      return
    } else {
      form.querySelector('input[name*="[progress]"]').classList.remove("input-error")
    }

    // Check if the comment is empty or just contains HTML formatting
    const isCommentEmpty = !commentContent ||
      commentContent.trim() === "" ||
      commentContent === "<div></div>" ||
      commentContent === "<div><br></div>"

    const progressChanged = progress !== parseInt(form.querySelector('input[name*="[progress]"]').defaultValue)
    const statusChanged = statusOverride !== form.querySelector('select[name*="[status_override]"]').defaultValue

    // Only require comment if progress or status has changed
    if ((progressChanged || statusChanged) && isCommentEmpty) {
      form.querySelector('trix-editor').classList.add("textarea-error")
      form.querySelector('input[type="submit"]').disabled = true
    } else {
      form.querySelector('trix-editor').classList.remove("textarea-error")
      form.querySelector('input[type="submit"]').disabled = false
    }
  }

  quickSet(event) {
    event.preventDefault()
    const value = event.currentTarget.dataset.value
    this.element.querySelector('input[name*="[progress]"]').value = value
    this.validateForm()
  }

  validateBeforeSubmit(event) {
    const form = this.element
    const progress = parseInt(form.querySelector('input[name*="[progress]"]').value)
    const statusOverride = form.querySelector('select[name*="[status_override]"]').value
    const commentContent = form.querySelector('trix-editor').value

    // Check if the comment is empty or just contains HTML formatting
    const isCommentEmpty = !commentContent ||
      commentContent.trim() === "" ||
      commentContent === "<div></div>" ||
      commentContent === "<div><br></div>"

    const progressChanged = progress !== parseInt(form.querySelector('input[name*="[progress]"]').defaultValue)
    const statusChanged = statusOverride !== form.querySelector('select[name*="[status_override]"]').defaultValue

    if ((progressChanged || statusChanged) && isCommentEmpty) {
      event.preventDefault()
      form.querySelector('trix-editor').classList.add("textarea-error")
    }
  }

  showError(message) {
    const errorDiv = this.element.querySelector('[id^="progress_update_error_"]')
    if (errorDiv) {
      errorDiv.innerHTML = `
        <div class="alert alert-error">
          <div class="flex-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6 mx-2">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"></path>
            </svg>
            <label>${message}</label>
          </div>
        </div>`
    }
  }

  clearError() {
    const errorDiv = this.element.querySelector('[id^="progress_update_error_"]')
    if (errorDiv) {
      errorDiv.innerHTML = ''
    }
  }

  handleSubmission(event) {
    if (event.detail.success) {
      // Clear the form content after successful submission
      const trixEditor = this.element.querySelector("trix-editor")
      if (trixEditor) {
        trixEditor.value = ""
      }
      // Reset progress input
      const progressInput = this.element.querySelector('input[type="number"]')
      if (progressInput) {
        progressInput.value = ""
      }
      // Close the details element
      const details = this.element.closest('details')
      if (details) {
        details.removeAttribute('open')
      }
    }
  }
} 