import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { 
    selectedParam: String,
    target: String,
    class: String
  }

  connect() {
    if (this.selectedParamValue === this.targetValue) {
      this.element.classList.add(...this.classValue.split(' '))
    }
  }
} 