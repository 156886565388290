import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "viewer", "editor", "trix"]

  connect() {
    // Initialize if needed
  }

  enterEditMode(event) {
    event.preventDefault()
    this.viewerTarget.classList.add("hidden")
    this.editorTarget.classList.remove("hidden")
  }

  exitEditMode(event) {
    event.preventDefault()
    this.editorTarget.classList.add("hidden")
    this.viewerTarget.classList.remove("hidden")
  }

  handleSubmit(event) {
    if (event.detail.success) {
      this.exitEditMode(event)
    }
  }

  toggleEdit(event) {
    event.preventDefault()
    const noteContent = this.element.querySelector('.note-content')
    const noteForm = this.element.querySelector('.note-form')
    
    if (noteContent) {
      noteContent.classList.add('fade-out')
    }
    if (noteForm) {
      noteForm.classList.remove('hidden')
      noteForm.classList.add('fade-in')
    }
  }
} 