// app/javascript/controllers/hotkey_controller.js
import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        hotkey: String
    }

    connect() {
        this.handleKeydown = this.handleKeydown.bind(this);
        document.addEventListener('keydown', this.handleKeydown);
    }

    disconnect() {
        document.removeEventListener('keydown', this.handleKeydown);
    }

    handleKeydown(event) {
        if (event.key === this.hotkeyValue) {
            this.element.click();
        }
    }
}
