import {Controller} from "@hotwired/stimulus"

export default class extends Controller {


    connect() {
        console.log('connect period_selector');

        // Check if `?period_id` is not set in the URL
        const urlParams = new URLSearchParams(window.location.search);
        if (!urlParams.has('period_id')) {
            // Execute the instructions only once if `?period_id` is not set
            const selectBox = this.element.querySelector("select");
            this.updateURL(selectBox);
            this.selectPeriod();
        }
    }

    navigate(event) {
        const direction = Number(event.currentTarget.getAttribute("data-direction"));
        const selectBox = this.element.querySelector("select");
        let selectedIndex = selectBox.selectedIndex + direction;
        const maxIndex = selectBox.options.length - 1;

        if (selectedIndex < 0) selectedIndex = 0;
        if (selectedIndex > maxIndex) selectedIndex = maxIndex;

        selectBox.selectedIndex = selectedIndex;
        this.updateURL(selectBox);
        this.selectPeriod();
    }

    selectPeriod() {
        const selectBox = this.element.querySelector("select");
        this.updateURL(selectBox);
        this.element.requestSubmit();
    }

    updateURL(selectBox) {
        const currentUrl = new URL(window.location);
        currentUrl.searchParams.set('period_id', selectBox.value);
        
        // Always preserve the selected parameter if it exists
        const selected = currentUrl.searchParams.get('selected');
        if (selected) {
            currentUrl.searchParams.set('selected', selected);
        }
        
        history.pushState({}, '', currentUrl);
    }
}
