import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["draggable"]

  connect() {
    this.debugTimeouts = new Map()
    this.draggableTargets.forEach(draggable => {
      this.debug('Enabling drag for item', {
        type: draggable.dataset.itemType,
        id: draggable.dataset.itemId
      })
      draggable.setAttribute("draggable", true)
    })
  }

  debug(message, data = {}, debounceMs = 100) {
    if (process.env.NODE_ENV !== 'development') return

    const key = `${message}-${JSON.stringify(data)}`
    if (this.debugTimeouts.has(key)) {
      clearTimeout(this.debugTimeouts.get(key))
    }

    this.debugTimeouts.set(key, setTimeout(() => {
      console.debug(`[DragDrop] ${message}`, data)
      this.debugTimeouts.delete(key)
    }, debounceMs))
  }

  // Helper to get element context for debugging
  getElementContext(element) {
    if (!element) return null

    const context = {
      tag: element.tagName?.toLowerCase(),
      id: element.id,
      classes: element.className,
      itemType: element.dataset?.itemType,
      itemId: element.dataset?.itemId,
      parentType: element.dataset?.parentType,
      parentId: element.dataset?.parentId,
      dataAttributes: {},
      parentContext: null
    }

    // Get all data attributes
    Object.keys(element.dataset || {}).forEach(key => {
      context.dataAttributes[key] = element.dataset[key]
    })

    // Get parent context (one level up)
    if (element.parentElement && element.parentElement !== this.element) {
      context.parentContext = {
        tag: element.parentElement.tagName?.toLowerCase(),
        id: element.parentElement.id,
        classes: element.parentElement.className,
        itemType: element.parentElement.dataset?.itemType,
        itemId: element.parentElement.dataset?.itemId
      }
    }

    return context
  }

  // Find the actual draggable parent from any child element
  findDraggableParent(element) {
    const draggable = element.closest('[data-drag-drop-target="draggable"]')
    if (!draggable) return null

    // Ensure we have the required data attributes
    if (!draggable.dataset.itemType || !draggable.dataset.itemId) {
      return null
    }

    return draggable
  }

  // Find the container for a specific item type within a parent
  findContainerInParent(parent, itemType) {
    this.debug("Finding container in parent", {
      parent: this.getElementContext(parent),
      itemType,
      parentHTML: parent?.outerHTML
    });

    if (!parent) {
      this.debug("No parent found");
      return null;
    }

    // For objectives, find the objectives-container
    if (itemType === 'objective') {
      const container = this.element.querySelector('.objectives-container');
      if (container) {
        this.debug("Found objectives container", {
          container: this.getElementContext(container)
        });
        return container;
      }
    }

    // For initiatives, find the initiatives-container within the parent key result's structure
    if (itemType === 'initiative') {
      // First check the next sibling which should be the div.mt-2.space-y-1.pl-8
      let container = null;
      let current = parent;

      // Look through next siblings until we find the initiatives container
      while (current && current.nextElementSibling) {
        current = current.nextElementSibling;

        // Check if this is the initiatives container
        if (current.classList.contains('initiatives-container')) {
          container = current;
          break;
        }

        // Check if this is the wrapper div that contains the initiatives container
        if (current.classList.contains('mt-2') && current.classList.contains('space-y-1')) {
          container = current.querySelector('.initiatives-container');
          if (container) break;
        }
      }

      if (container) {
        this.debug("Found initiatives container", {
          container: this.getElementContext(container),
          containerHTML: container.outerHTML
        });
        return container;
      }

      this.debug("No initiatives container found", {
        parentSiblings: Array.from(parent.parentElement?.children || [])
          .map(el => this.getElementContext(el))
      });
    }

    // For key results, find the container using team ID and objective ID
    if (itemType === 'key_result') {
      const teamId = this.element.dataset.teamId;
      if (!teamId) {
        this.debug('Missing team ID', {
          elementDataset: this.element.dataset,
          elementClasses: this.element.className
        });
        return null;
      }

      // Debug all potential containers in the entire controller element
      const allTurboFrames = this.element.querySelectorAll('turbo-frame');
      this.debug('All turbo-frames in controller', {
        count: allTurboFrames.length,
        teamId,
        frames: Array.from(allTurboFrames).map(frame => ({
          id: frame.id,
          parentClasses: frame.parentElement?.className,
          isKeyResults: frame.id?.endsWith('_keyresults'),
          framePattern: `team_${teamId}_objective_\\d+_keyresults`,
          matchesPattern: frame.id?.match(new RegExp(`team_${teamId}_objective_(\\d+)_keyresults`)),
          objectiveId: frame.id?.match(new RegExp(`team_${teamId}_objective_(\\d+)_keyresults`))?.[1]
        }))
      });

      // Strategy 1: Find turbo-frame by ID pattern with team ID
      const objectiveId = parent.dataset.itemId;
      const keyResultsFrame = Array.from(allTurboFrames).find(frame => {
        const pattern = new RegExp(`team_${teamId}_objective_${objectiveId}_keyresults`);
        return pattern.test(frame.id);
      });

      if (keyResultsFrame) {
        this.debug('Found container via ID pattern', {
          containerId: keyResultsFrame.id,
          teamId,
          objectiveId,
          pattern: `team_${teamId}_objective_${objectiveId}_keyresults`
        });
        return keyResultsFrame;
      }

      // Strategy 2: Through keyresults-container within objective
      const keyresultsContainer = this.element.querySelector(
        `[data-item-type="objective"][data-item-id="${objectiveId}"] .keyresults-container`
      );

      if (keyresultsContainer) {
        const frames = keyresultsContainer.querySelectorAll('turbo-frame');
        const container = Array.from(frames).find(frame => {
          const pattern = new RegExp(`team_${teamId}_objective_${objectiveId}_keyresults`);
          return pattern.test(frame.id);
        });

        if (container) {
          this.debug('Found container via keyresults-container', {
            containerId: container.id,
            teamId,
            objectiveId,
            pattern: `team_${teamId}_objective_${objectiveId}_keyresults`
          });
          return container;
        }
      }
    }

    this.debug("No container found in parent");
    return null;
  }

  // Helper to get all ancestor classes
  getAncestorClasses(element) {
    const classes = []
    let current = element.parentElement
    while (current && current !== this.element) {
      if (current.className) {
        classes.push({
          tag: current.tagName?.toLowerCase(),
          classes: current.className
        })
      }
      current = current.parentElement
    }
    return classes
  }

  // Find the parent element that should contain the draggable items
  findParentElement(element, itemType) {
    const elementContext = this.getElementContext(element)
    this.debug('Finding parent element', {
      itemType,
      elementContext,
      controllerElement: {
        id: this.element.id,
        classes: this.element.className
      }
    })

    switch (itemType) {
      case 'objective':
        return this.element

      case 'key_result': {
        let current = element
        let traversalPath = []

        while (current && current !== this.element) {
          const currentContext = this.getElementContext(current)
          traversalPath.push(currentContext)

          // Check for objective
          if (current.dataset?.itemType === 'objective') {
            this.debug('Found objective parent', {
              objectiveId: current.dataset.itemId,
              path: 'Direct match',
              traversalPath
            })
            return current
          }

          // Check for keyresults container
          if (current.classList.contains('keyresults-container')) {
            const objective = current.closest('[data-item-type="objective"]')
            if (objective) {
              this.debug('Found objective parent', {
                objectiveId: objective.dataset.itemId,
                path: 'Through keyresults-container',
                containerContext: this.getElementContext(current),
                objectiveContext: this.getElementContext(objective),
                traversalPath
              })
              return objective
            }
          }

          // Check for parent with data-parent-type="objective"
          if (current.dataset?.parentType === 'objective') {
            const objectiveId = current.dataset.parentId
            const objective = this.element.querySelector(`[data-item-type="objective"][data-item-id="${objectiveId}"]`)
            if (objective) {
              this.debug('Found objective parent', {
                objectiveId: objective.dataset.itemId,
                path: 'Through parent-type attribute',
                currentContext: this.getElementContext(current),
                objectiveContext: this.getElementContext(objective),
                traversalPath
              })
              return objective
            }
          }

          current = current.parentElement
        }

        this.debug('No objective parent found', {
          elementContext,
          traversalPath,
          controllerElement: {
            id: this.element.id,
            classes: this.element.className,
            html: this.element.outerHTML
          }
        })
        return null
      }

      case 'initiative': {
        // Similar approach for initiatives
        let current = element
        while (current && current !== this.element) {
          // Direct key_result match
          if (current.dataset?.itemType === 'key_result') {
            this.debug('Found key_result parent', {
              keyResultId: current.dataset.itemId,
              path: 'Direct match'
            })
            return current
          }

          // Through initiatives container
          if (current.classList.contains('initiatives-container')) {
            const keyResult = current.closest('[data-item-type="key_result"]')
            if (keyResult) {
              this.debug('Found key_result parent', {
                keyResultId: keyResult.dataset.itemId,
                path: 'Through initiatives-container'
              })
              return keyResult
            }
          }

          // Through parent-type attribute
          if (current.dataset?.parentType === 'keyresult') {
            const keyResultId = current.dataset.parentId
            const keyResult = this.element.querySelector(`[data-item-type="key_result"][data-item-id="${keyResultId}"]`)
            if (keyResult) {
              this.debug('Found key_result parent', {
                keyResultId: keyResult.dataset.itemId,
                path: 'Through parent-type attribute'
              })
              return keyResult
            }
          }

          current = current.parentElement
        }

        this.debug('No key_result parent found', {
          elementId: element.dataset?.itemId,
          parentType: element.dataset?.parentType,
          parentId: element.dataset?.parentId,
          elementHtml: element.outerHTML
        })
        return null
      }

      default:
        return null
    }
  }

  findContainer(itemType, element) {
    const elementContext = this.getElementContext(element)
    this.debug('Finding container', {
      itemType,
      elementContext,
      controllerElement: {
        id: this.element.id,
        classes: this.element.className
      }
    }, 500)

    const draggable = this.findDraggableParent(element)
    if (!draggable) {
      this.debug('No draggable parent found', {
        elementContext,
        draggableSearch: {
          closestDraggable: element.closest('[data-drag-drop-target]')?.outerHTML,
          parentElements: Array.from(element.parentElements || []).map(p => this.getElementContext(p))
        }
      })
      return null
    }

    const parent = this.findParentElement(draggable, itemType)
    if (!parent) {
      this.debug('No parent element found', {
        itemType,
        draggableContext: this.getElementContext(draggable),
        elementContext
      })
      return null
    }

    const container = this.findContainerInParent(parent, itemType)
    this.debug('Container search result', {
      itemType,
      parentContext: this.getElementContext(parent),
      containerContext: this.getElementContext(container),
      draggableContext: this.getElementContext(draggable)
    }, 500)

    return container
  }

  dragStart(event) {
    const draggable = this.findDraggableParent(event.target)
    if (!draggable) return

    this.debug('Drag started', {
      type: draggable.dataset.itemType,
      id: draggable.dataset.itemId
    })

    draggable.classList.add("dragging")
    event.dataTransfer.effectAllowed = "move"
    event.dataTransfer.setData("application/json", JSON.stringify({
      itemType: draggable.dataset.itemType,
      itemId: draggable.dataset.itemId
    }))
  }

  dragEnd(event) {
    const draggable = this.findDraggableParent(event.target)
    if (!draggable) return

    draggable.classList.remove("dragging")
  }

  dragOver(event) {
    event.preventDefault()

    const draggable = this.findDraggableParent(event.target)
    if (!draggable) return

    const draggedItem = this.element.querySelector(".dragging")
    if (!draggedItem || draggedItem === draggable) return

    const itemType = draggedItem.dataset.itemType
    if (draggable.dataset.itemType !== itemType) return

    const draggedContainer = this.findContainer(itemType, draggedItem)
    const targetContainer = this.findContainer(itemType, draggable)

    if (!draggedContainer || !targetContainer) {
      this.debug('Container not found', {
        draggedContainerId: draggedContainer?.id,
        targetContainerId: targetContainer?.id
      })
      return
    }

    // For key results, allow moving between different containers
    if (itemType === 'key_result') {
      // Both containers should be turbo frames for key results
      if (!draggedContainer.tagName.toLowerCase() === 'turbo-frame' ||
        !targetContainer.tagName.toLowerCase() === 'turbo-frame' ||
        !draggedContainer.id.endsWith('_keyresults') ||
        !targetContainer.id.endsWith('_keyresults')) {
        this.debug('Invalid containers for key result movement', {
          draggedContainer: this.getElementContext(draggedContainer),
          targetContainer: this.getElementContext(targetContainer)
        })
        return
      }
    } else if (draggedContainer !== targetContainer) {
      // For other types, containers must match
      this.debug('Container mismatch', {
        draggedContainerId: draggedContainer?.id,
        targetContainerId: targetContainer?.id
      })
      return
    }

    const items = [...targetContainer.querySelectorAll(`[data-item-type="${itemType}"]`)]
    const draggedIndex = items.indexOf(draggedItem)
    const dropIndex = items.indexOf(draggable)

    this.debug('Drag over positions', {
      itemType,
      draggedId: draggedItem.dataset.itemId,
      targetId: draggable.dataset.itemId,
      draggedIndex,
      dropIndex,
      draggedHTML: draggedItem.outerHTML,
      targetHTML: draggable.outerHTML,
      containerChildren: Array.from(targetContainer.children).map(child => ({
        type: child.dataset?.itemType,
        id: child.dataset?.itemId,
        classes: child.className
      }))
    })

    if (draggedIndex !== dropIndex || draggedContainer !== targetContainer) {
      // Find the associated content containers
      const draggedContent = this.findAssociatedContent(draggedItem, itemType)
      const targetContent = this.findAssociatedContent(draggable, itemType)

      this.debug('Associated content', {
        draggedContent: draggedContent?.map(el => this.getElementContext(el)),
        targetContent: targetContent?.map(el => this.getElementContext(el))
      })

      if (draggedIndex < dropIndex || draggedContainer !== targetContainer) {
        // Moving down or to a different container
        if (targetContent?.length) {
          const lastTargetContent = targetContent[targetContent.length - 1]
          lastTargetContent.after(draggedItem)
          draggedContent?.forEach(content => draggedItem.after(content))
        } else {
          draggable.after(draggedItem)
          draggedContent?.forEach(content => draggedItem.after(content))
        }
      } else {
        // Moving up in the same container
        draggable.before(draggedItem)
        draggedContent?.forEach(content => draggedItem.after(content))
      }

      // Update the parent references when moving between containers
      if (draggedContainer !== targetContainer) {
        const newObjectiveId = targetContainer.id.match(/team_\d+_objective_(\d+)_keyresults/)?.[1]
        if (newObjectiveId) {
          draggedItem.dataset.parentId = newObjectiveId
          draggedItem.dataset.parentType = 'objective'
        }
      }
    }
  }

  // Helper to find associated content (keyresults container, initiatives container, etc.)
  findAssociatedContent(element, itemType) {
    if (!element) return null

    const content = []
    let current = element.nextElementSibling

    if (itemType === 'objective') {
      // For objectives, look for keyresults container and new keyresult frame
      while (current && (
        current.classList.contains('mt-2') ||
        current.classList.contains('keyresults-container') ||
        current.tagName.toLowerCase() === 'turbo-frame'
      )) {
        content.push(current)
        current = current.nextElementSibling
      }
    } else if (itemType === 'key_result') {
      // For key results, look for initiatives container
      while (current && (
        current.classList.contains('mt-2') ||
        current.classList.contains('initiatives-container')
      )) {
        content.push(current)
        current = current.nextElementSibling
      }
    }

    return content
  }

  async drop(event) {
    event.preventDefault()

    try {
      const data = JSON.parse(event.dataTransfer.getData("application/json"))
      const teamId = this.element.dataset.teamId
      const periodId = this.element.dataset.periodId

      if (!teamId || !periodId) {
        throw new Error("Missing team or period context")
      }

      const container = this.findContainer(data.itemType, event.target)
      if (!container) {
        throw new Error(`Container not found for ${data.itemType}`)
      }

      const items = Array.from(
        container.querySelectorAll(`[data-item-type="${data.itemType}"]`)
      ).map(item => item.dataset.itemId)

      this.debug('Sending reorder request', {
        type: data.itemType,
        items,
        container: container.dataset.testid
      })

      // Handle API endpoint naming
      const endpointType = data.itemType === 'key_result' ? 'keyresults' : `${data.itemType}s`
      await this.sendReorderRequest(teamId, periodId, endpointType, items)

    } catch (error) {
      console.error("[DragDrop] Error:", error)
      this.element.dispatchEvent(
        new CustomEvent("drag-drop:error", {
          detail: { error: error.message },
          bubbles: true
        })
      )
    }
  }

  async sendReorderRequest(teamId, periodId, itemType, items) {
    // Build the base request body
    const body = {
      item_ids: items
    }

    // For both key results and initiatives, we need to find the parent
    if (itemType === 'keyresults' || itemType === 'initiatives') {
      const firstItem = this.element.querySelector(`[data-item-id="${items[0]}"]`)
      const parentType = itemType === 'keyresults' ? 'key_result' : 'initiative'
      const parent = this.findParentElement(firstItem, parentType)

      if (!parent) {
        throw new Error(`Parent not found for ${parentType}`)
      }

      const parentItemType = parent.dataset.itemType
      const parentId = parent.dataset.itemId

      this.debug('Found parent for reorder', {
        itemType,
        parentItemType,
        parentId,
        items
      })

      // Add the appropriate parent ID parameter
      if (parentItemType === 'objective') {
        body.objective_id = parentId
      } else if (parentItemType === 'key_result') {
        body.keyresult_id = parentId
      }

      this.debug('Sending reorder request', {
        teamId,
        periodId,
        itemType,
        items,
        parentItemType,
        parentId,
        body
      })
    } else {
      // For objectives, just send the item_ids
      this.debug('Sending objective reorder request', {
        teamId,
        periodId,
        items,
        body
      })
    }

    // Initiatives are handled as key results in the backend
    const endpointType = itemType === 'initiatives' ? 'keyresults' : itemType

    const response = await fetch(`/teams/${teamId}/periods/${periodId}/${endpointType}/reorder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
      },
      body: JSON.stringify(body)
    })

    if (!response.ok) {
      throw new Error(`Server error: ${response.status}`)
    }

    return response
  }
} 