import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source"]

  copy(event) {
    event.preventDefault()

    const text = this.sourceTarget.value || this.sourceTarget.textContent

    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
        .then(() => {
          // Optional: Show a success message or change button text temporarily
          const button = event.currentTarget
          const originalText = button.innerHTML
          button.innerHTML = "Copied!"

          setTimeout(() => {
            button.innerHTML = originalText
          }, 2000)
        })
        .catch(err => {
          console.error('Failed to copy text: ', err)
        })
    } else {
      // Fallback for browsers that don't support clipboard API
      this.sourceTarget.select()
      document.execCommand('copy')
    }
  }
} 