import {Application} from "@hotwired/stimulus"

const application = Application.start()

// Enable Stimulus debugging
application.debug = true
window.Stimulus = application

function toggleSubteams(button) {
    const subteams = button.nextElementSibling;
    if (subteams) {
        subteams.classList.toggle('hidden');
    }
}

export {application}
