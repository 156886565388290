// Custom Trix attachment for @mentions
import Trix from "trix"

// Initialize Trix configuration
if (!Trix.config) {
  Trix.config = {}
}

if (!Trix.config.textAttributes) {
  Trix.config.textAttributes = {}
}

if (!Trix.config.attachments) {
  Trix.config.attachments = {}
}

// Configure mention attachments
const configureMentions = (event) => {
  console.group('Trix Mention Configuration')
  console.log('Initial Trix Config:', JSON.parse(JSON.stringify(Trix.config)))

  // Register mention as a direct attachment type
  Trix.config.attachments.mention = {
    contentType: "mention",
    presentation: "inline",
    permanent: true,
    caption: false,
    previewable: false,
    render(attachment) {
      // Create a simpler representation for the editor
      // The actual rendering in the view will be handled by the _mention.html.erb partial
      const wrapper = document.createElement("span")
      wrapper.className = "mention inline-flex items-center gap-1 px-2 py-0.5 rounded-full text-sm font-medium bg-emerald-100 text-emerald-800"

      // Set all necessary data attributes
      const rawUserId = attachment.getAttributes().userId || attachment.getAttributes()['data-user-id'] || (attachment.getAttributes().name || '').split('@')[0]
      // Ensure userId is numeric, default to 1 if not
      const userId = /^\d+$/.test(rawUserId) ? rawUserId : '1'
      const name = attachment.getAttributes().name || attachment.getAttributes()['data-name'] || ''
      const email = attachment.getAttributes().userId || attachment.getAttributes()['data-user'] || `${rawUserId}@demo.xxx`

      wrapper.dataset.user = email
      wrapper.dataset.name = name
      wrapper.dataset.userId = userId
      wrapper.contentEditable = false

      // Create avatar placeholder (will be replaced with proper avatar in view)
      const avatarSpan = document.createElement("span")
      avatarSpan.className = "w-4 h-4 rounded-full bg-emerald-200 flex items-center justify-center text-xs font-bold"
      const initials = name
        .split('@')[0]
        .split(' ')
        .map(part => part[0])
        .join('')
        .toUpperCase()
        .slice(0, 2)
      avatarSpan.textContent = initials || 'DE'

      // Create name container
      const nameSpan = document.createElement("span")
      nameSpan.textContent = `@${name.split('@')[0] || userId || 'user'}`

      wrapper.appendChild(avatarSpan)
      wrapper.appendChild(nameSpan)
      return wrapper
    }
  }

  // Tell Trix how to present mentions in the editor
  Trix.config.textAttributes.mention = {
    parser: (element) => {
      return {
        userId: element.dataset.user,
        name: element.dataset.name,
        'data-user': element.dataset.user,
        'data-name': element.dataset.name,
        'data-user-id': element.dataset.userId
      }
    }
  }

  console.log('Final Trix Config:', JSON.parse(JSON.stringify(Trix.config)))
  console.groupEnd()
}

// Initialize only on Trix initialization
document.addEventListener("trix-before-initialize", configureMentions)

// Process mentions in the view (not just in the editor)
document.addEventListener("turbo:load", processMentionsInView)
document.addEventListener("DOMContentLoaded", processMentionsInView)

function processMentionsInView() {
  console.log("Processing mentions in view")

  // Find all trix-content elements that might contain mentions
  const trixContents = document.querySelectorAll('.trix-content')

  trixContents.forEach(content => {
    // Process figure elements with data-trix-attachment that are mentions
    const mentionFigures = content.querySelectorAll('figure[data-trix-attachment]')
    mentionFigures.forEach(figure => {
      try {
        // Parse the attachment data
        const attachmentData = JSON.parse(figure.getAttribute('data-trix-attachment'))

        // Check if it's a mention attachment
        if (attachmentData.contentType === 'mention') {
          // Create a proper mention element
          const mentionContent = document.createElement('div')
          mentionContent.innerHTML = attachmentData.content

          // Replace the figure with the actual mention content
          figure.parentNode.replaceChild(mentionContent.firstChild, figure)
        }
      } catch (error) {
        console.error('Error processing mention attachment:', error)
      }
    })

    // Process direct mentions that aren't wrapped with hoverable
    // Use a simpler selector and filter in JavaScript to avoid CSS syntax errors
    const allMentions = content.querySelectorAll('span.mention')
    allMentions.forEach(mention => {
      // Skip if this mention is already inside a mention-wrapper
      let isInsideMentionWrapper = false
      let parent = mention.parentElement
      while (parent && parent !== content) {
        if (parent.classList && parent.classList.contains('mention-wrapper')) {
          isInsideMentionWrapper = true
          break
        }
        parent = parent.parentElement
      }

      if (isInsideMentionWrapper) {
        return // Skip this mention as it's already wrapped
      }

      // Only process if it has the necessary data attributes
      if (mention.dataset.user && mention.dataset.name) {
        // Create hoverable wrapper
        const hoverableWrapper = document.createElement('span')
        hoverableWrapper.className = 'mention-wrapper inline-flex items-center'

        // Create the hoverable container
        const hoverable = document.createElement('div')
        hoverable.className = 'relative group/hoverable'
        hoverable.setAttribute('data-controller', 'popover')

        // Clone the mention to preserve its content
        const mentionClone = mention.cloneNode(true)
        mentionClone.classList.add('hover:bg-emerald-200', 'transition-colors', 'duration-150')

        // Create the popover content
        const popoverContent = document.createElement('div')
        popoverContent.className = 'absolute z-[100] hidden group-hover/hoverable:block transition-opacity duration-150 ease-in-out'
        popoverContent.style.left = '0'
        popoverContent.style.top = '100%'
        popoverContent.setAttribute('data-popover-target', 'content')

        // Create the popover inner content
        const popoverInner = document.createElement('div')
        popoverInner.className = 'bg-base-200 shadow-lg rounded-lg p-4 mt-2 min-w-[200px]'

        // Create user info section
        const userInfo = document.createElement('div')
        userInfo.className = 'flex items-center gap-4'

        // Get the numeric user ID - either from dataset.userId or default to 1
        // Make sure we're using a numeric ID, not a string that might be a name
        const numericUserId = /^\d+$/.test(mention.dataset.userId) ? mention.dataset.userId : '1';

        // Create avatar
        const avatarLink = document.createElement('a')
        avatarLink.className = 'hover:opacity-75'
        avatarLink.href = `/users/${numericUserId}`
        avatarLink.setAttribute('data-turbo-frame', '_top')

        const avatarContainer = document.createElement('div')
        avatarContainer.className = 'w-12 h-12 rounded-full flex items-center justify-center text-white'
        avatarContainer.style.backgroundColor = '#1E1F20'

        const avatarInitials = document.createElement('span')
        avatarInitials.className = 'text-xs font-medium'
        avatarInitials.textContent = mention.querySelector('span')?.textContent || 'DE'

        avatarContainer.appendChild(avatarInitials)
        avatarLink.appendChild(avatarContainer)

        // Create user details
        const userDetails = document.createElement('div')
        userDetails.className = 'flex-1 min-w-0'

        const userName = document.createElement('div')
        userName.className = 'font-medium'

        const userNameText = document.createElement('div')
        userNameText.className = 'truncate'
        userNameText.textContent = mention.dataset.name

        userName.appendChild(userNameText)
        userDetails.appendChild(userName)

        // Add avatar and user details to user info
        userInfo.appendChild(avatarLink)
        userInfo.appendChild(userDetails)

        // Add additional user info
        const additionalInfo = document.createElement('div')
        additionalInfo.className = 'mt-3 space-y-2'

        const userEmail = document.createElement('div')
        userEmail.className = 'text-sm text-base-content/70'
        userEmail.textContent = mention.dataset.user

        const userBadges = document.createElement('div')
        userBadges.className = 'flex flex-wrap gap-1 mt-1'

        const userBadge = document.createElement('span')
        userBadge.className = 'badge badge-sm badge-primary'
        userBadge.textContent = mention.dataset.user

        userBadges.appendChild(userBadge)
        additionalInfo.appendChild(userEmail)
        additionalInfo.appendChild(userBadges)

        // Add last seen info
        const lastSeen = document.createElement('div')
        lastSeen.className = 'text-xs text-base-content/60'
        lastSeen.textContent = 'Last seen: recently'

        additionalInfo.appendChild(lastSeen)

        // Add footer with profile link
        const footer = document.createElement('div')
        footer.className = 'mt-4 pt-3 border-t border-base-300'

        const footerActions = document.createElement('div')
        footerActions.className = 'flex gap-2'

        const profileLink = document.createElement('a')
        profileLink.className = 'btn btn-sm btn-ghost flex-1'
        profileLink.href = `/users/${numericUserId}`
        profileLink.setAttribute('data-turbo-frame', '_top')

        const profileIcon = document.createElement('svg')
        profileIcon.setAttribute('aria-hidden', 'true')
        profileIcon.setAttribute('width', '24')
        profileIcon.setAttribute('height', '24')
        profileIcon.setAttribute('viewBox', '0 0 24 24')
        profileIcon.setAttribute('fill', 'none')
        profileIcon.setAttribute('stroke', 'currentColor')
        profileIcon.setAttribute('stroke-width', '2')
        profileIcon.setAttribute('stroke-linecap', 'round')
        profileIcon.setAttribute('stroke-linejoin', 'round')
        profileIcon.className = 'w-4 h-4 mr-1'

        const iconPath = document.createElement('path')
        iconPath.setAttribute('d', 'M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2')

        const iconCircle = document.createElement('circle')
        iconCircle.setAttribute('cx', '12')
        iconCircle.setAttribute('cy', '7')
        iconCircle.setAttribute('r', '4')

        profileIcon.appendChild(iconPath)
        profileIcon.appendChild(iconCircle)

        const profileLinkText = document.createTextNode('View Profile')

        profileLink.appendChild(profileIcon)
        profileLink.appendChild(profileLinkText)

        footerActions.appendChild(profileLink)
        footer.appendChild(footerActions)

        // Assemble the popover
        popoverInner.appendChild(userInfo)
        popoverInner.appendChild(additionalInfo)
        popoverInner.appendChild(footer)
        popoverContent.appendChild(popoverInner)

        // Assemble the hoverable
        hoverable.appendChild(mentionClone)
        hoverable.appendChild(popoverContent)

        // Add the hoverable to the wrapper
        hoverableWrapper.appendChild(hoverable)

        // Replace the original mention with the hoverable wrapper
        mention.parentNode.replaceChild(hoverableWrapper, mention)
      } else {
        // If the mention doesn't have data attributes, try to extract them from the content
        // This handles mentions that were created without proper data attributes
        const nameSpan = mention.querySelector('span:last-child')
        if (nameSpan) {
          const name = nameSpan.textContent.replace('@', '')
          if (name) {
            // Set the data attributes on the mention
            mention.dataset.user = `${name}@demo.xxx`
            mention.dataset.name = name
            // Default to user ID 1 instead of using the name as an ID
            mention.dataset.userId = '1'

            // Now process it again (recursive call)
            processMentionsInView()
          }
        }
      }
    })
  })
} 