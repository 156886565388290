import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:submit-end", this.handleSubmission.bind(this))
  }

  handleSubmission(event) {
    if (event.detail.success) {
      // Clear the form content after successful submission
      this.element.querySelector("trix-editor").value = ""
    }
  }
} 